input[type=email] {
    width: 90vw;
    height: 6vh;
    margin: 8px 0;
    box-sizing: border-box;
    font-size: large;
  }
  
#pageHeading{
    text-align: center;
  }

#email{

  
}
#messsage{
  width: 100%;
}

.label{
  color:#61DAFB;
  margin:0;
}

#submitButton{
  position: relative;
    right: 50;
    height: 100px;
    width: 200px;
    background-color: #FFFFFF;
    border: none;
    border-radius: 20px;
}