#page{
    width: 100vw;
    height: 100vh;
    background-color: #3263E1;
    display: flex;
    justify-content: center;
    background-size: 300% 300%;
  background-image: linear-gradient(-45deg, #3263E1 0%, #3263E1 25%, #3263E1 51%, #ff357f 100%);
  -webkit-animation: AnimateBG 10s ease-in-out infinite;
          animation: AnimateBG 10s ease-in-out infinite;
}
@-webkit-keyframes AnimateBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes AnimateBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
#mainContainer{
    display: flex;
    flex-direction: column;
    background-color: #1E1E1E;    
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    padding: 10%;
    align-self: center;
}

.subContainer{
    display: flex;
    flex-direction: column;
    align-self: center;
    
}
.timeUnit{
    align-self: center;
    justify-self: center;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 3rem;
    font-family: "Consolas" ;
}
.time{
display: flex;
}
.arrow{
    width: 64px;
    cursor: pointer;
}


.hour{
    
    color: white;
}
.minute{
    color: white;
}
.second{
    color: white;
}
.divider{
    align-self: center;
    font-size: 2rem;
}
.button{
width: 90%;
height: 40px;
align-self: center;
border: none;
border-radius: .25rem;
color: white;
font-size: 2rem;
transition: ease-in-out;
display: flex;
justify-content: center;
}
.arrow:hover{
  transform: translateY(-5px);
}
.arrowDown:hover{
  transform: translateY(+5px);
  transform: rotate(180deg),
}
#startButton{
    background-color: #3263E1;
}
#pauseButton{
    display: none;
    background-color:#FF2400 ;
}
#button:hover{
    padding: 1.75rem;
    opacity: 90%;
}