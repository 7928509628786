body{
  background-color:#242222 ;
  font-family:"Hind Siliguri";
  color: white;
  margin: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

#root{
  overflow-x: hidden;
}
.logo{
  display: flex;
  color:#61DAFB ;
  font-size: 48px;
  padding: 0;
  margin: 0;
  padding: .25%;
  text-decoration: none;
  transition: ease-in-out .5s;
}
.logo:hover{
  transform: rotate(15deg);
}
.logo:visited{
  color: #61DAFB;
  text-decoration: none;
}
.links{
  display: flex;
  justify-content: space-between;
}
.link{
  align-self: center;
  padding: 10px;
  font-size: 20px ;
  margin: 0;
  text-decoration: none;
  color: white;
}

#content{
  display: flex;
  margin-left:2% ;
  margin-right:2% ;
  flex-direction: column;
}
#bio{
  font-size:1.5rem ;
}
#portrait{
  float: left;
  width: 20vw;
  height: 20vw;
  max-width:512px;
  border-radius: 50%;
  margin-right: 5%;
  background-image: url("../images/portrait.jpg");
  background-position: center;
  background-size: cover;
}
#showcase{
  display: flex;

}

#featured{
  display: flex;
  flex-direction: column; 
}

.preview{
  width: 22vw;
  max-width: 226px;
  height: 260px;
  background-size:cover;
  background-position: center;
  border-radius: 10px;
  border: none;
  margin: 10px;
  transition: ease-in .15s;
  }
.preview:hover{
  border: white medium solid;
  transform: translateY(-10%);
  
}
.previewtitle{
  margin: 0;
}
@media only screen and (max-width: 600px) {
  #burger-menu{
    width: 32px;
  } 
#intro{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#portrait{
  width: 50vw;
  height: 50vw;
  margin-top: 5%;
  justify-self: center;
  align-self: center;
}
  #showcase{
    display:flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
  }

  .preview{
    align-self: center;
    width: 100%;
    max-width: none;
    height: 45vh;
    margin: 0;
    margin-bottom: 5%;
    background-position: center;
  }
  .preview:hover{ 
    border: white medium solid;
  } 
}


a{
  color: inherit;
  text-decoration: none;}
a:visited{
  text-decoration: none;
  color: inherit;
}


