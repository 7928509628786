
#board{
  background-color:white;
  z-index: 1;
  margin:0;
  min-width: fit-content;
  display: block;
  touch-action: none;
  cursor: crosshair;
}

.app{
  font-family:"Roboto";
  user-select: none;
  width: fit-content;
  overflow-y: hidden;
  user-zoom:none;
  position:static;
}

.menusContainer svg:hover {
  background-color: black;
  border: medium white solid;
  border-radius: 5%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .menusContainer{
  display: flex;
  flex-direction:row;
  position: absolute;
  top: 25vh;
  left: 0;
  margin-left: 16px;
}
.menu{
  display: flex;
  flex-direction: column  ;
  background-color: #242222;
  padding:32px;
  border-radius: 10px;
  height: fit-content;
  width: fit-content;
}

#menuIcons{
  align-self: center;
  margin-bottom: 32px;
}

#toolMenu{
  height: fit-content;
  align-self: center;
  padding: 8px;
  display: flex;
  flex-direction:column ;
}

#shapeMenu{
  margin-left: 8px;
  padding: 8px;
}

#textSettings{
  margin-top: 16px;
  position: absolute;
  display: flex;
  top:0;
  right: 50vw;
  background-color: #242222;
  padding:1.5%;
  border-radius: 10px;
}

}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
   .menusContainer{
      display: flex;
      flex-direction: column-reverse;
      bottom: 0 ;
      position: absolute;
      width: 100vw;
      justify-content: center;
      min-width: fit-content;
  }
  .menu{
    display: flex;
    flex-direction: row  ;
    background-color: #242222;
    border-radius: 10px;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  #menuIcons{
    margin-bottom: 0px;
  }
  #toolMenu{
    height: fit-content;
    align-self: center;
    padding: 8px;
    display: flex;
    flex-direction:row ;
  }
  #pencilMenu{
    flex-direction: column;
    padding: 0px;
    width: fit-content;
    align-self: center;
  }
  #toolContainer{
    flex-direction: column-reverse;
  }

  #textSettings{
    margin-top: 16px;
    position: absolute;
    display: flex;
    top:0;
    width: fit-content;
    background-color: #242222;
    padding:1.5%;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
#toolContainer{
  display: flex;
  margin-left: 8px; 
  height: fit-content; 
}

#pencilMenu{
  margin-left: 8px;
  padding: 16px;
  padding-top: 8px;
  }


.selected{
  padding: 5px;
  border: medium solid white;
  border-radius: 10px;  
}
.btn{
    display: flex ;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 0;
    border-radius: 10%;
    color: black;
    height: 50px;
    width: 80px;

}
.btn:hover{
    opacity: 80%;

}
.raise:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
  }

  #view{
    bottom: 100;
    background-color: white;
    overflow: scroll;
    cursor: none;
  }

  .dropdown{
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-height: 0;
    background-color: #242222;
    align-self: center;
    border-radius:5%;
  }
  .dropdownContent{
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 200ms;
    position: absolute;
    margin: 0;
    padding: 0;
    align-self: center;
  }
  .dropdownContent.open{
    grid-template-rows: 1fr;
  
  }
  #Add{
    color: aqua;
    padding-right: 25px;
   }
   #Tool{
    
    padding-right: 25px;
   }
   #Text{
    color: aqua;
   }
   #strokeWidth{
    width: 80%;
    align-self: center;
    
   }
   .icon{
    padding-left: 10px;
    padding-right: 10px;
    fill: #2E2D2D;
  }
   .icon:hover{
    background-color: #2E2D2D;
    border: medium solid gray;
    border-radius: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
   }
   .toolBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
   }
 
#colorSelector{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.colorRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.colorOption{
  display:inline-block;
  width: 30px;
  height: 30px;
  margin: 2%;
  border-radius: 50%;
  cursor:pointer;
  
}
.colorOption:hover{
  border: medium solid;
  border-color:white ;
}
#cursor{
  width: 5px;
  height: 5px;
  border: 2px solid black;
  border-radius:50% ;
  position: absolute;
  z-index: 9;
  pointer-events: none ;
  
}

.select{
  width: max-content;
  background-color:white;
  border:none;
  padding: .5rem;
  border-radius: 5px;
  margin: .5rem;
}
#pageNav{
  position: absolute;
  bottom: 0;
  left: 50vw;
  color: red;
}

#textBox{
  position: absolute;
  height: fit-content;
  width: 10vw;
  min-width: 128px;
  color: #242222;
  z-index: 1;
 display: block;
 flex-direction: column;
 pointer-events:auto;
 
 cursor:text;
 font-size: 16px;
}

#closeTextbox{
  align-self: flex-end;
}


label#imageUpload  input[type ="file"]{
  display: none;
}