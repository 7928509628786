
#page{
    height: 100%;
    width: 100%;
    font-family: Cabazon;
    background-color: black;
}

#navbar{
    width: 100vw;
    height:fit-content;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}
#logo{
    height: 96px;
    width: 96px;
}
#cart{
    height: 32px;
    width: 32px;
    padding-right:10% ;
}
#links{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 33vw;
    font-size: 20px;
}
#showcase{
    width: 100%;
    max-width: 100vw;
    height: 70vw;
    max-height: 70vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}
.banner{
    position: relative;
    width: fit-content;
    height: fit-content;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.banner #orderBtn{
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: #F01D7F;
    width: 96px;
    height: 64px;
    border: none;
    border-radius: 25px;
    color: #FFFFFF;
    font-size: 32px;
}
.product{
    text-align: center;
    width: 33vw;
    height: fit-content;
}
.productImage{
    width: 100%;
}
.reviewHearts{
    margin:0;
    margin-top:10px;
    padding: 0;
}
.feedback{
    display:inline-block;
}